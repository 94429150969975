app.factory('product', ['Config','$window','API_call','helper',function(Config, $window, API_call,h) {
    var factoryFns =  {
        /**
         * Gets products. See each switch case for more information
         * @param type
         * @param width
         * @param height
         * @returns {*}
         */
        getProducts: function(type, width, height) {
            var queryParams, searchTerm, apiSegment;
            switch (type.key) {
                case 'category':
                    // We get a list of products in a given category
                    var slug = type.value;
                    queryParams = {
                        'slug': slug,
                        'width': width,
                        'height': height,
                        'type': type.key, // = category
                        'attributes': 'key_feature'};
                    apiSegment = 'products';
                    break;
                case 'search':
                    // We get a list of products that match a given query string
                    searchTerm = type.value;
                    queryParams = {
                        'term': searchTerm,
                        'width': width,
                        'height': height,
                        'attributes': 'key_feature'
                    };
                    apiSegment = 'search';
                    break;
                default:
                    // Otherwise, we just display an error. There's no switch case matching
                    console.error("(product-service.js) Section type:", type.key);
                    apiSegment = '{invalid-section:' + type.key + '}';
            }
            // We return the $http object, so we can concatenate it with a .then(function() {})
            return API_call(apiSegment, queryParams);
        },
        /**
         * Gets a single product from the API.
         * @unused Not used in ExamTables because we retrieve the product from the server side (to create <meta tags>,
         * so we avoid double-request)
         * @param width
         * @param height
         * @param zoom_height
         * @param zoom_width
         * @returns {*}
         */
        getProduct: function(width, height, zoom_height, zoom_width){
            var productSlug = $window.productSlug,
                queryParams = {
                    'product-slug': productSlug,
                    'section-type': 'category',
                    'width': width,
                    'height': height,
                    'zoom-width': zoom_width,
                    'zoom-height': zoom_height,
                    'attributes': 'key_feature'
                };
            return API_call('product', queryParams);
        },
        /**
         * @unused Not used in ExamTables because we only have 5 products
         * Gets all four product groups from the API (new arrivals, featured, etc)
         * @param width
         * @param height
         * @param limit
         * @returns {*}
         */
        getProductGroups: function(width, height, limit){
            var queryParams = {
                'group-types': 'new_arrivals|highest_rated|featured|special_offers',
                'limit': limit,
                'width': width,
                'height': height
            };
            return API_call('products', queryParams);
        },
        /**
         * Formats the rating (out of 5) into a percentage (out of 100).
         * If the rating is 0 we return the message no_reviews_yet.
         * @why (Because "0 out of 5" is not the same as "no reviews yet")
         * @param rating
         * @returns {*}
         */
        rating: function(rating) {
            var r = Number(rating);
            if (r === 0) {
                return h.message('no_reviews_yet');
            } else {
                return Math.round(20 * r).toFixed(0);
            }
        }
    };

    return factoryFns;

}]);