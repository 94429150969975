app.controller('HomeController',
    ['$scope','Config','$timeout','cart','toaster','product','helper','$window',
        function($scope,Config,$timeout,cart,toaster,product,h,$window){

            $scope.productGroups = [];
            $scope.base_url = Config.base_url;
            $scope.productService = product;
            $scope.category = $window.catSlugToName;
            $scope.asset = h.asset;
            $scope.route = h.route;
            $scope.price = h.price;
            $scope.cartService = cart;
            $scope.stn = $window.catSlugToName;
            $scope.products = {};
            $scope.productQty = {};

            /**
             * @unused Not used in Examtables
             */
            $scope.getProductGroups = function(){

                // Limit number of products per section/product_group
                var limit = 6;

                // We get the product groups...
                $scope.productService.getProductGroups(Config.width, Config.height, limit).then(function(response) {
                    this.final = {};
                    this.pl = [];
                    // Iterate over each of the products
                    angular.forEach(response.data, function($v,$k) {
                        if ($v.name === Config.homepage_main_section.slug) {
                            // If the product belongs to the "main section" group:
                            this.final.main = {name: Config.homepage_main_section.name, products: $v.products};
                        } else {
                            // Else, we just add all the products to the $scope.productGroups[{category-name}]
                            this.final[$v.name] = $v.products;
                            // We add all the products to one array
                            this.pl = this.pl.length ? this.pl.concat($v.products) : $v.products;
                        }
                    }, this);

                    // We set the scope based on the this context.
                    $scope.productGroups = this.final;
                    $scope.productsList = this.pl;

                    // Hide loader, etc
                    h.init();

                }, h.ajaxError);
            };

            /**
             * Gets the products that will be displayed in the home page.
             */
            $scope.getProducts = function() {
                $scope.productService.getProducts({key: 'category', value: Config.brand_key}, 350, 350)
                    .then(function(response) {
                        // We create an empty object to populate it...
                        this.products = {
                            featured: {},
                            other: []
                        };
                        angular.forEach(response.data, function($v, $k) {
                            if ($v.product_id == Config.featured_product_id) {
                                // if the product IS the featured product, it has a special place
                                this.products.featured = $v;
                            } else {
                                // else we just add it to the list of products
                                this.products.other.push($v)
                            }
                        }, this);

                        // We set the scope based on the this context.
                        $scope.products = this.products;
                        $scope.pf = this.products.featured;

                        // Hide loader, etc
                        h.init();

                    }, h.ajaxError);
            };

            // Increases the quantity of the given productID. The logic is in cart_service.js
            $scope.incQty = function(productID) {
                $scope.productQty[productID] = cart.incQty(productID);
            };
            // Decreases the quantity of the given productID. The logic is in cart_service.js
            $scope.decQty = function(productID) {
                $scope.productQty[productID] = cart.decQty(productID);
            };
            // Changes the quantity of the given productID. The logic is in cart_service.js
            $scope.chgQty = function(productID) {
                $scope.productQty[productID] = cart.chgQty(productID, $scope.productQty[productID]);
            };
            // Adds a product to the cart and resets its quantity to 1
            $scope.cartAdd = function(product) {
                cart.add(product, $scope.productQty[product.product_id]);
                $scope.productQty[product.product_id] = 1;
            };
        }]);